document.addEventListener("DOMContentLoaded", function () {
  let currentPage = 1;
  let shortTime = 100;
  const loadMoreButton = document.getElementById("load-more");

  if (loadMoreButton) {
    loadMoreButton.addEventListener("click", function () {
      let maxPages = this.getAttribute("data-php-value");
      currentPage++;
      fetch("/wp-admin/admin-ajax.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "spring_load_more_posts",
          paged: currentPage,
        }),
      })
        .then((response) => response.text())
        .then((data) => {
          document.querySelector(".articles-section-inner").insertAdjacentHTML("beforeend", data);

          setTimeout(() => {
            document.querySelectorAll(".articles-section-inner .fade-in").forEach((element) => {
              element.classList.remove("fade-in");
            });
          }, shortTime);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      if (currentPage >= maxPages) {
        this.style.display = "none";
      }
    });
  }
});
