addEventListener("load", () => {
  const scrollDeadzone = 50;
  const menuContainer = document.querySelector("#menu-container");

  var prevScrollpos = window.scrollY;
  var scrollSinceLastChange = 0;
  var isShowing = true;
  var latestScrollDirection = 1;

  addEventListener("scroll", () => {
    var currentScrollPos = window.scrollY;
    
    // Prevent menu hiding when bouncing at the top or bottom in Safari
    if (currentScrollPos <= 0 || currentScrollPos >= document.documentElement.scrollHeight - window.innerHeight) {
      return;
    }

    if (Math.sign(currentScrollPos - prevScrollpos) != latestScrollDirection) {
      scrollSinceLastChange = 0;
    }
    scrollSinceLastChange += currentScrollPos - prevScrollpos;
    latestScrollDirection = Math.sign(currentScrollPos - prevScrollpos);
    prevScrollpos = currentScrollPos;
    if (isShowing) {
      if(scrollSinceLastChange > scrollDeadzone){
        if (!document.body.classList.contains("menu-is-showing")) {
          isShowing = false;
          menuContainer.classList.add("hidden");
        }
      }
    } else {
      if (scrollSinceLastChange < -scrollDeadzone) {
        isShowing = true;
        menuContainer.classList.remove("hidden");
      }
    }
  });
});