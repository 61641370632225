// Function for loading more articles
function loadMoreArticles() {
  let currentPage = 1;
  let shortTime = 100;
  const loadMoreButton = document.getElementById("load-more-articles");
  if (loadMoreButton) {
    loadMoreButton.addEventListener("click", function () {
      let maxPages = this.getAttribute("data-page");
      currentPage++;
      const formData = new FormData(document.getElementById("filter-form"));
      formData.append("action", "spring_load_more_articles");
      formData.append("paged", currentPage);

      fetch("/wp-admin/admin-ajax.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.text())
        .then((data) => {
          document.querySelector(".articles-section-inner").insertAdjacentHTML("beforeend", data);

          setTimeout(() => {
            document.querySelectorAll(".articles-section-inner .fade-in").forEach((element) => {
              element.classList.remove("fade-in");
            });
          }, shortTime);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      if (currentPage >= maxPages) {
        this.style.display = "none";
      }
    });
  }
}

// Function for fold out button on categories selection
document.addEventListener("DOMContentLoaded", function () {
  loadMoreArticles();
  const foldOutButton = document.getElementById("categories-foldout-button");
  const duration = 500;
  if (foldOutButton) {
    let filterContainer = document.getElementById("categories-selection");
    if (filterContainer) {
      var mobileSize = window.matchMedia("(max-width: 520px)");
      if (mobileSize.matches) {
        filterContainer.style.height = "0px";
      }
    }
    foldOutButton.addEventListener("click", function () {
      let filterContainerHeight = document.getElementById("categories-selection").offsetHeight;
      let filterContainerInnerHeight = document.getElementById("categories-selection-inner").offsetHeight;
      if (filterContainerHeight === 0) {
        filterContainer.style.height = filterContainerInnerHeight + "px";
        filterContainer.classList.add("animating");
        filterContainer.classList.add("expanded");
        foldOutButton.classList.add("categories-collapse-button");
        foldOutButton.innerText = "Stäng filter";
        setTimeout(function () {
          filterContainer.classList.remove("animating");
        }, duration);
      } else {
        filterContainer.style.height = "0px";
        filterContainer.classList.add("animating");
        filterContainer.classList.remove("expanded");
        foldOutButton.classList.remove("categories-collapse-button");
        foldOutButton.innerText = "Filtrera";
        setTimeout(function () {
          filterContainer.classList.remove("animating");
        }, duration);
      }
    });
  }
});

// Function for aligning checkboxes
function adjustCheckboxAlignment() {
  const container = document.querySelector(".checkboxes");
  if (container) {
    const containerWidth = container.clientWidth;
    const children = Array.from(container.children);
    const childrenLength = children.length;
    const childrenWidth = children.reduce((total, child) => total + child.clientWidth, 0);
    const childrenGapWidth = (childrenLength - 1) * 30 + childrenWidth;
    if (childrenGapWidth > containerWidth) {
      container.classList.add("left-aligned");
    } else {
      container.classList.remove("left-aligned");
    }
  }
}

// Perform function on page load and page rezise
window.addEventListener("DOMContentLoaded", adjustCheckboxAlignment);
window.addEventListener("resize", adjustCheckboxAlignment);

function loadMoreFilteredArticles() {
  let currentPage = 1;
  let postsShowing = 6;
  const loadMoreButton = document.getElementById("load-more-btn");

  if (loadMoreButton) {
    loadMoreButton.addEventListener("click", function () {
      currentPage++;
      // const currentPage = parseInt(loadMoreButton.getAttribute("data-page")) || 1;
      const numberOfPosts = parseInt(loadMoreButton.getAttribute("data-total")) || 6;
      const formData = new FormData();
      formData.append("action", "load_more_filtered_posts"); // Anropar PHP-funktionen
      formData.append("paged", currentPage);

      fetch("/wp-admin/admin-ajax.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.text())
        .then((data) => {
          if (data.trim() !== "") {
            document.querySelector(".articles-section-inner").insertAdjacentHTML("beforeend", data);
            postsShowing = postsShowing + 6;
            if (postsShowing >= numberOfPosts) {
              loadMoreButton.style.display = "none";
            }
          } else {
            // Om det inte finns fler inlägg, dölj knappen
            loadMoreButton.style.display = "none";
          }
        })
        .catch((error) => console.error("Error:", error));
    });
  }
}

// Kör funktionen för att ladda in fler artiklar
window.addEventListener("DOMContentLoaded", loadMoreFilteredArticles);
